import { axios } from "@/services/axios/config";

/**
 * API function to contact support (SAV)
 * @param payload 
 * @returns { Promise }
 */
export const contactSav = async (payload: any) => {
    return axios.post('notif/sav', payload)
            .then((response) => {
                return Promise.resolve(response)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
}

/**
 * API function to verify if client can be deactivate
 * @param id 
 * @param accessToken 
 * @returns { Promise }
 */
export const verifyClientStatus = async (id: number,accessToken : string) => {
    const config = {
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
    };
    return axios.get('auth/deactivate_client/'+id,config)
            .then((response) => {
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
}
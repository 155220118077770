<script setup lang="ts">
import CustomInput from "@/views/components/CustomInput.vue";
import savEmailShema from "@/validation/savEmailSchema";
import { contactSav, verifyClientStatus } from "@/services/modules/savService";
import { getCookie, getUserFromLocalStorage, isAuth } from "@/utils/functions";
import { reactive, ref, onMounted, Ref } from "vue";
// @ts-ignore
import { useI18n } from "vue-i18n";
import { useAlertsStore } from "@/stores/alerts";

// Props
const props = withDefaults(
  defineProps<{
    id: string;
    title?: string;
    titleClasses?: any[];
    paragraph?: string;
    paragraphClasses?: any[];
    hideEmail?: boolean;
    hideName?: boolean;
    hideSubject?: boolean;
    source: string;
    subject?: string;
    style?: any;
    message?: string;
    classes?: any[];
    btnContainerClasses?: any[];
    btnClasses: any[];
    btnLabel: string;
    intro: string;
    outro: string;
    messageLabel?: string;
    proPlatformCode?: string | null;
    deactivateAccount?: boolean;
  }>(),
  {
    proPlatformCode: null,
    deactivateAccount: false,
  }
);

// Local variables
const { t } = useI18n();
const user = getUserFromLocalStorage() ?? null
// @ts-ignore
const VITE_ASSET_URL = import.meta.env.VITE_ASSET_URL;

// Stores
const alerts = useAlertsStore();

// Refs
const loading = ref(false);
const sendSuccess = ref(false);
const savModalTrigger: Ref<HTMLElement | null> = ref(null);
const deactivateAccount = ref(false)
const canDeactivateAccount = ref(true)

// Reactive variables
const savEmailFormData = reactive({
  email: "",
  name: "",
  subject: "",
  message: "",
});

const savEmailErrors = reactive({
  email: "",
  name: "",
  subject: "",
  message: "",
});

// Lifecycle hooks
onMounted(() => {
  if (props.deactivateAccount !== undefined) {
    deactivateAccount.value = props.deactivateAccount
    verifyClient()
  }
  if (isAuth()) {
    savEmailFormData.email = user?.email??'';
    // savEmailFormData.email = 'topela.testing@yopmail.com'
    if (user?.first_name) {
      savEmailFormData.name = user.first_name + " " + user.last_name;
    } else {
      savEmailFormData.name = user?.last_name??'';
    }

    if (props.subject) {
      savEmailFormData.subject = props.subject;
    }

    if (props.message) {
      savEmailFormData.message = props.message;
    }
  }
});

const verifyClient = () => {
  const cookieToken = getCookie("topela_token")
  let account_id = user !== null ? user.party_id : null
  // let token = user !== null ? user.token.access : null
  let token =  cookieToken?.access ?? null
  if (account_id !== null) {
    verifyClientStatus(account_id, token)
      .then((response) => {
        if (response.data.status === "success") {
          canDeactivateAccount.value = true
        } else {
          canDeactivateAccount.value = false
        }
      })
      .catch(() => {});
  }

};

const onSubmitMessage = () => {
  (savEmailErrors.email = ""),
    (savEmailErrors.name = ""),
    (savEmailErrors.subject = ""),
    (savEmailErrors.message = "");

  let payload = {};

  if (props.proPlatformCode) {
    payload = {
      account_id: isAuth() ? user?.account_id : null,
      email: savEmailFormData.email,
      last_name: savEmailFormData.name,
      message: savEmailFormData.message,
      source: props.source,
      subject: savEmailFormData.subject,
      intro: props.intro,
      outro: props.outro,
      pro_platform_code: props.proPlatformCode,
    };
  } else {
    payload = {
      account_id: isAuth() ? user?.account_id : null,
      email: savEmailFormData.email,
      last_name: savEmailFormData.name,
      message: savEmailFormData.message,
      source: props.source,
      subject: savEmailFormData.subject,
      intro: props.intro,
      outro: props.outro,
    };
  }

  savEmailShema
    .validate(savEmailFormData, { abortEarly: false })
    .then(() => {
      loading.value = true;
      alerts.clearAlerts()
      contactSav(payload)
        .then(() => {
          loading.value = false;
          sendSuccess.value = true;
          // if (savModalTrigger.value !== null) {
          //   savModalTrigger.value.click();
          //   savModalTrigger.value=null
          // }
          // alerts.putAlert({ code: 'message_send_success', scope: 'general' })
        })
        .catch(() => {
          alerts.putAlert({ code: "message_send_fail", scope: "general" });
          loading.value = false;
        });
    })
    .catch((error) => {
      error.inner.forEach((e: { path: string; message: string }) => {
        // @ts-ignore
        savEmailErrors[e.path] = e.message;
      });
    });
};
</script>

<template>
  <form
    v-if="!sendSuccess"
    class="text-start"
    :class="classes"
    @submit.prevent="onSubmitMessage"
    id="contactForm"
  >
    <h4 v-if="title" :class="titleClasses">{{ title }}</h4>
    <div v-if="deactivateAccount && !canDeactivateAccount" class="alert p-2 alert-danger" role="alert">
      <h6 class="text-danger">{{ t("w_txt_csProfillDeactivateAccount_attention") }} </h6>
      {{ t("w_txt_csProfillDeactivateAccount_client_can_not_be_deactivate") }}
    </div>
    <p v-if="paragraph" :class="paragraphClasses" class="mb-3">
      {{ paragraph }}
    </p>
    <div class="row">
      <div class="col-md-6 mb-3" v-show="!props.hideName">
        <CustomInput
          id="sav_name"
          v-model:value="savEmailFormData.name"
          :disabled="isAuth()"
          :placeholder="t('w_frm_plName')"
          :errors="savEmailErrors.name"
          :classes="['text-start', 'rounded-pill', 'mb-0']"
        />
      </div>
      <div class="col-md-6 mb-3" v-show="!props.hideEmail">
        <CustomInput
          id="sav_email"
          v-model:value="savEmailFormData.email"
          :disabled="isAuth()"
          :placeholder="t('w_frm_plEmail')"
          :errors="savEmailErrors.email"
          :classes="['text-start', 'rounded-pill', 'mb-0']"
        />
      </div>
      <div class="col-md-12 mb-3" v-show="!props.hideSubject">
        <CustomInput
          id="sav_subject"
          v-model:value="savEmailFormData.subject"
          :placeholder="t('g_frm_plSubject')"
          :errors="savEmailErrors.subject"
          :classes="['text-start', 'rounded-pill', 'mb-0']"
        />
      </div>
      <div class="col-md-12 mb-3">
        <div class="form-group text-start mb-3">
          <label v-if="props.messageLabel" for="message" class="form-label">{{
            props.messageLabel
          }}</label>
          <textarea
            class="form-control rounded mb-0"
            v-model="savEmailFormData.message"
            id="message"
            :placeholder="t('g_frm_plMessage')"
            rows="7"
          ></textarea>
          <span
            v-if="savEmailErrors.message"
            class="text-danger text-start ms-2"
            >{{ savEmailErrors.message }}</span
          >
        </div>
      </div>

      <div class="col-md-12 mb-3">
        <slot></slot>
        <div :class="btnContainerClasses">
          <slot name="cancelButton"></slot>
          <button class="btn on-action-btn" :class="btnClasses" id="submit" type="submit">
            <span v-if="!loading">{{ btnLabel }}</span>
            <div
              v-else
              class="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div v-else>
    <div>
      <img
        class="mb-3"
        :src="
          VITE_ASSET_URL +
          'assets/public/assets/img/undraw_Mail_sent_re_0ofv.webp'
        "
        alt=""
        height="150"
      />
    </div>
    <h5 class="px-md-3" v-html="t('w_msg_messageSent')"></h5>
  </div>

  <button
    v-show="false"
    ref="savModalTrigger"
    data-bs-toggle="modal"
    :data-bs-target="`#${id}`"
  ></button>
</template>

<style scoped>
.form-check-input:checked {
  background-color: #fb9307;
  border-color: #fb9307;
}

.form-control:focus {
  border-color: #fb9307;
  /* box-shadow: 0 0 0 0.02rem #FB9307; */
  /* padding-left: 1.5rem; */
}
</style>
